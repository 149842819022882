import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import Card from "../components/Card";
import SiteMetadata from "../components/SiteMetaData";
import BeitraegeSidebar from "../components/BeitraegeSidebar";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { checkRole } from "../components/Utils";

const dateToMonth = (date) => date.split("-")[1];
const dateToYear = (date) => date.split("-")[2];

const BeitraegePage = ({ data, location }) => {
  const allDates = data.allContentfulPost.nodes
    .filter((item) => item.draft !== true)
    .map((item) => item.publishedAt);

  const existingYears = [
    ...new Set(allDates.map((item) => item.split("-")[2]).sort()),
  ];
  const existingMonths = [
    ...new Set(allDates.map((item) => item.split("-")[1]).sort()),
  ];

  const currentYear = Math.max(...existingYears.map(Number)).toString();
  const state = location.state;
  const givenAuthor = state?.author;
  const givenCategory = state?.category;
  const givenKeyword = state?.keyword;
  const givenMonth = state?.month;
  const givenYear = state?.year;
  const givenSerie = state?.serie;
  const [filteredMonth, setFilteredMonth] = useState(
    givenMonth ? givenMonth : ""
  );
  const [filteredSerie, setFilteredSerie] = useState(
    givenSerie ? givenSerie : ""
  );
  const [filteredYear, setFilteredYear] = useState(givenYear ? givenYear : "");
  const [filteredAuthor, setFilteredAuthor] = useState(
    givenAuthor ? givenAuthor : ""
  );

  const [filteredCategory, setFilteredCategory] = useState(
    givenCategory ? givenCategory : ""
  );

  const [filteredKeyword, setFilteredKeyword] = useState(
    givenKeyword ? givenKeyword : ""
  );

  const allPosts = data.allContentfulPost.nodes;

  const postsWithSerie = allPosts
    .filter((item) => (item.serie ? item.serie.title : false))
    .reverse();
  const existingTags = [...new Set(allPosts.map((item) => item.tags).flat())];
  const filteredTagsUsingMainSearchBar = existingTags.filter((item) =>
    item.toLowerCase().includes(filteredKeyword.toLowerCase())
  );
  const keywordFilter = (item) => {
    if (filteredKeyword.length < 3) return true;
    if (item.title.toLowerCase().includes(filteredKeyword.toLowerCase()))
      return true;
    return (
      filteredTagsUsingMainSearchBar.filter((tag) => item.tags.includes(tag))
        .length > 0
    );
  };

  const filteredCards = allPosts
    .filter((item) => item.draft !== true)
    .filter((item) =>
      filteredMonth ? dateToMonth(item.publishedAt) === filteredMonth : true
    )
    .filter((item) =>
      filteredYear ? dateToYear(item.publishedAt) === filteredYear : true
    )
    .filter((item) => (filteredAuthor ? item.author === filteredAuthor : true))
    .filter((item) =>
      filteredCategory ? item.category.title === filteredCategory : true
    )
    .filter(keywordFilter)
    .filter((item) =>
      filteredSerie
        ? (item.serie ? item.serie.title : null) === filteredSerie
        : true
    );

  const postsPerPage = 15;
  const numberOfPages = Math.ceil(filteredCards.length / postsPerPage);
  const pageNumbers = Array.from(Array(numberOfPages).keys());

  const [activePage, setActivePage] = useState(0);

  let pagesWithPosts = [];
  pageNumbers.forEach((item) =>
    pagesWithPosts.push(
      filteredCards.slice(item * postsPerPage, (item + 1) * postsPerPage)
    )
  );

  useEffect(() => {
    setActivePage(0);
  }, [
    filteredKeyword,
    filteredYear,
    filteredMonth,
    filteredAuthor,
    filteredCategory,
    filteredSerie,
  ]);

  const { user } = useAuth0();

  const contentful = require("contentful-management");
  async function Connect() {
    let client = contentful.createClient({
      accessToken: "CFPAT-jLjpO9lQUXtJ4wgWWXTuwdayKLQMXWZ0s7aUsX9km3U",
    });
    let space = await client.getSpace("j3cnxbdaejpl");
    return await space.getEnvironment("master");
  }
  const [likeList, setLikeList] = useState({});
  const [readCommentsList, setReadCommentsList] = useState({});
  const [countComment, setCountComment] = useState({});

  useEffect(() => {
    (async () => {
      let env = await Connect();
      let entries = await env.getEntries({ content_type: "portfolio" });
      entries = entries.items.filter((a) => a.fields.comments);

      let userLiked = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        userLiked[id] = entry.fields.likes["de"];
      });
      let countsComment = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        countsComment[id] =
          entry.fields.comments["de"].length +
          entry.fields.comments["de"]
            .map((item) => item.replies?.length)
            .filter((item) => item > 0)
            .reduce((a, b) => a + b, 0);
      });
      let userReadComments = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        userReadComments[id] = entry.fields.comments["de"][0].read;
      });
      setLikeList(userLiked);
      setReadCommentsList(userReadComments)
      setCountComment(countsComment);
    })();
  }, []);

  const Cards = () => {
    return filteredCards.length > 0 ? (
      <div className="grid grid-cols-3 gap-3">
        {pagesWithPosts[activePage]?.map((item, index) => (
          <Card
            postsWithSerie={postsWithSerie}
            card={item}
            filteredKeyword={filteredKeyword}
            filteredSerie={filteredSerie}
            key={index}
            likeList={likeList}
            user={user}
            readCommentsList={readCommentsList}
            countComment={countComment}
          />
        ))}{" "}
      </div>
    ) : (
      <div className="absolute h-full w-full">
        <div className="text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Keine Treffer
        </div>
      </div>
    );
  };

  const PageNumber = () => {
    return (
      pageNumbers.length > 1 && (
        <div className="my-5">
          {pageNumbers.map((number, key) => (
            <button
              className={`mr-2 p-2 rounded-md ${
                activePage === key
                  ? "bg-gray-300 dark:bg-gray-100"
                  : "bg-gray-100 dark:bg-gray-300"
              }`}
              key={key}
              onClick={() => setActivePage(number)}
            >
              <p className="text-sm dark:text-black">{number + 1}</p>
            </button>
          ))}
        </div>
      )
    );
  };

  const Unauthorized = () => {
    return (
      !checkRole(user) && (
        <div className="bg-white rounded-lg p-3 shadow-sm">
          Du hast keinen Zugriff auf diese Seite.
        </div>
      )
    );
  };
  return (
    <Layout setFilteredKeyword={setFilteredKeyword}>
      <SiteMetadata title="Beiträge" description="Die Beiträge im Überblick" />
      <div className="bg-gray-50 pb-6 flex-grow dark:bg-gray-900 dark:text-white">
        <div className="container">
          <div className="text-center text-4xl py-6 lg:hidden">
            Alle Beiträge
          </div>
          <Unauthorized />
          {checkRole(user) && (
            <div className="md:flex">
              <BeitraegeSidebar
                setFilteredKeyword={setFilteredKeyword}
                setFilteredYear={setFilteredYear}
                setFilteredMonth={setFilteredMonth}
                setFilteredAuthor={setFilteredAuthor}
                setFilteredCategory={setFilteredCategory}
                filteredKeyword={filteredKeyword}
                filteredYear={filteredYear}
                filteredMonth={filteredMonth}
                filteredAuthor={filteredAuthor}
                filteredCategory={filteredCategory}
                existingYears={existingYears}
                currentYear={currentYear}
                existingMonths={existingMonths}
                filteredSerie={filteredSerie}
                setFilteredSerie={setFilteredSerie}
              />
              <div className="md:w-3/4 md:pr-0.75 order-1 relative">
                <Cards />
                <PageNumber />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default withAuthenticationRequired(BeitraegePage);

export const query = graphql`
  query BeitraegeQuery {
    allContentfulPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        contentful_id
        draft
        slug
        author
        serie {
          title
        }
        tags
        publishedAt(formatString: "DD-MM-YYYY")
        category {
          title
        }
        gallery {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 1000
            aspectRatio: 1.77
          )
        }
      }
    }
    allContentfulCategory {
      nodes {
        title
      }
    }
  }
`;
