import { FaTrashAlt } from "react-icons/fa";
import { Input, Select } from "@chakra-ui/react";
import { Link } from "gatsby";
import { monthDict } from "./Utils";
import React from "react";

const FilterOptions = ({
  filteredYear,
  filteredAuthor,
  handleChangeAuthor,
  filteredMonth,
  existingAuthors,
  filteredSerie,
  filteredKeyword,
  setFilteredKeyword,
  setFilteredMonth,
  existingYears,
  handleChangeMonth,
  latestSerie,
  setFilteredAuthor,
  setFilteredCategory,
  existingMonths,
  filteredCategory,
  handleChangeCategory,
  handleChangeYear,
  existingCategories,
  setFilteredSerie,
  setFilteredYear,
}) => {
  const Category = () => {
    return (
      <div className="mb-4">
        <div className="flex">
          <div>Kategorie</div>
          {filteredCategory !== "" && (
            <button className="ml-1" onClick={() => setFilteredCategory("")}>
              <FaTrashAlt size={12} className="opacity-30" />
            </button>
          )}
        </div>
        <div className="">
          <Select
            placeholder="Alle"
            onChange={handleChangeCategory}
            value={filteredCategory}
          >
            {existingCategories.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  const Serie = () => {
    return (
      <div className="mb-4">
        <div className="flex">
          <div className="">Reihe</div>
          {filteredSerie !== "" && (
            <button className="ml-1" onClick={() => setFilteredSerie("")}>
              <FaTrashAlt size={12} className="opacity-30" />
            </button>
          )}
        </div>
        <Link
          to="./reihen"
          state={{
            link: true,
            author: filteredAuthor,
            category: filteredCategory,
            month: filteredMonth,
            year: filteredYear,
            keyword: filteredKeyword,
            serie: filteredSerie,
          }}
        >
          <div className="border border-gray-200 rounded-md w-full">
            <div className="p-1 text-left ml-3">
              {filteredSerie ? (
                filteredSerie
              ) : (
                <div className="opacity-50">{`z.B. ${latestSerie}`}</div>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const Keyword = () => {
    return (
      <div className="mb-4">
        <div className="flex items-center">
          <div>Suchbegriff</div>{" "}
          {filteredKeyword !== "" && (
            <button className="ml-1" onClick={() => setFilteredKeyword("")}>
              <FaTrashAlt size={12} className="opacity-30" />
            </button>
          )}
        </div>
        <Input
          value={filteredKeyword}
          placeholder="mindestens 3 Zeichen"
          onChange={(e) => setFilteredKeyword(e.target.value)}
        />
      </div>
    );
  };

  const DateAndAuthor = () => {
    return (
      <div className="flex md:flex-col">
        {/* Date */}
        <div className="mb-4">
          <div className="flex mr-2 md:mr-0">
            <div className="mr-2">
              <div className="flex items-center">
                <div>Jahr</div>{" "}
                {filteredYear !== "" && (
                  <button
                    className="ml-1"
                    onClick={() => {
                      setFilteredMonth("");
                      setFilteredYear("");
                    }}
                  >
                    <FaTrashAlt size={12} className="opacity-30" />
                  </button>
                )}
              </div>
              <Select
                placeholder="Alle"
                onChange={handleChangeYear}
                value={filteredYear}
              >
                {existingYears.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </Select>
            </div>
            {filteredYear > 0 && (
              <div>
                <div className="flex items-center">
                  <div>Monat</div>
                  {filteredMonth !== "" && (
                    <button
                      className="ml-1"
                      onClick={() => setFilteredMonth("")}
                    >
                      <FaTrashAlt size={12} className="opacity-30" />
                    </button>
                  )}
                </div>
                <Select
                  placeholder="Alle"
                  onChange={handleChangeMonth}
                  value={filteredMonth}
                >
                  {existingMonths.map((item, index) => (
                    <option value={item} key={index}>
                      {monthDict[item]}
                    </option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>

        {/*Author*/}
        <div className="mb-4">
          <div className="flex">
            <div>Autor</div>
            {filteredAuthor !== "" && (
              <button className="ml-1" onClick={() => setFilteredAuthor("")}>
                <FaTrashAlt size={12} className="opacity-30" />
              </button>
            )}
          </div>
          <Select
            placeholder="Alle"
            onChange={handleChangeAuthor}
            value={filteredAuthor}
          >
            {existingAuthors.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  return (
    <>
      <Category />
      <Serie />
      {Keyword()}
      <DateAndAuthor />
    </>
  );
};

export default FilterOptions;
