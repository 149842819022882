import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gerFormatShort } from "./Utils";
import { FaHeart, FaTag, FaComment } from "react-icons/fa";

const Card = ({
  card,
  filteredKeyword,
  filteredSerie,
  postsWithSerie,
  likeList,
  user,
  readCommentsList,
  countComment,
}) => {
  return (
    <Link
      to={`/beitraege/${card.slug.toLowerCase()}`}
      className="bg-yellow-200 dark:bg-gray-500 rounded-lg overflow-hidden transform shadow-sm"
    >
      <div className="relative">
        <div className="relative">
          <GatsbyImage
            image={card.gallery[0].gatsbyImageData}
            alt={card.title}
          />
          {likeList[card.contentful_id] !== undefined &&
            user?.sub &&
            likeList[card.contentful_id].some((d) => d.userId === user.sub) && (
              <FaHeart className="text-red-500 absolute right-0 top-0 transform -translate-x-2 translate-y-2" />
            )}
          {readCommentsList[card.contentful_id] !== undefined &&
            user?.sub &&
            !readCommentsList[card.contentful_id].some(
              (d) => d.userId === user.sub
            ) &&
            countComment[card.contentful_id] &&
            countComment[card.contentful_id] - 1 !== 0 && (
              <FaComment
                className={`text-blue-500 absolute right-0 top-0 transform translate-y-2 ${
                  likeList[card.contentful_id].some(
                    (d) => d.userId === user.sub
                  )
                    ? "-translate-x-7.5"
                    : "-translate-x-2"
                }`}
              />
            )}
        </div>
        {card.serie && (
          <div className="text-white absolute bottom-0 right-0 pr-2 pb-2 text-2xl z-60">
            {filteredSerie
              ? postsWithSerie
                  .filter((a) =>
                    card.serie ? a.serie.title === card.serie.title : false
                  )
                  .indexOf(card) + 1
              : null}
          </div>
        )}
      </div>
      <div className="p-2">
        <div className="text-sm opacity-50 dark:text-white">
          {gerFormatShort(card.publishedAt)}
        </div>
        <div className="font-medium dark:text-white">{card.title}</div>
        {filteredKeyword.length > 2 &&
          card.tags
            .join("")
            .toLowerCase()
            .includes(filteredKeyword.toLowerCase()) && (
            <div className="flex items-center text-sm flex-wrap">
              <FaTag size={10} className="mr-1" />
              <p className="break-all">
                {
                  card.tags.filter((a) =>
                    a.toLowerCase().includes(filteredKeyword.toLowerCase())
                  )[0]
                }
              </p>
            </div>
          )}
      </div>
    </Link>
  );
};

export default Card;
