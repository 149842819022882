import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { FaChevronUp, FaChevronDown, FaChevronRight } from "react-icons/fa";
import FilterOptions from "./FilterOptions";

const BeitraegeSidebar = ({
  setFilteredKeyword,
  setFilteredYear,
  setFilteredMonth,
  setFilteredAuthor,
  setFilteredCategory,
  setFilteredSerie,
  filteredSerie,
  filteredKeyword,
  filteredYear,
  filteredMonth,
  filteredAuthor,
  filteredCategory,
  existingYears,
  existingMonths,
}) => {
  const { allContentfulPost, allContentfulCategory } = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          author
          title
          draft
          publishedAt(formatString: "DD-MM-YYYY")
          serie {
            title
          }
        }
      }
      allContentfulCategory {
        nodes {
          title
          order
        }
      }
    }
  `);

  const postsWithSerie = allContentfulPost.nodes
    .filter((item) => item.serie)
    .filter((item) => item.draft !== true);

  const latestSerie = postsWithSerie.map((item) => item.serie.title)[0];

  const existingCategories = allContentfulCategory.nodes
    .sort((a, b) => a.order - b.order)
    .map((item) => item.title);

  const allAuthors = allContentfulPost.nodes
    .filter((item) => item.draft !== true)
    .map((item) => item.author);
  const existingAuthors = [...new Set(allAuthors.map((item) => item))];

  //>>> handle Change
  const handleChangeMonth = (event) => {
    event.preventDefault();
    setFilteredMonth(filteredYear ? event.target.value : "");
  };
  const handleChangeYear = (event) => {
    event.preventDefault();
    setFilteredYear(event.target.value);
    if (!event.target.value) {
      setFilteredMonth("");
    }
  };
  const handleChangeAuthor = (event) => {
    event.preventDefault();
    setFilteredAuthor(event.target.value);
  };

  const handleChangeCategory = (event) => {
    event.preventDefault();
    setFilteredCategory(event.target.value);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setFilteredKeyword("");
    setFilteredYear("");
    setFilteredMonth("");
    setFilteredAuthor("");
    setFilteredCategory("");
    setFilteredSerie("");
  };
  //<<< handleChange

  //for visibility of reset button
  const activeFilter =
    filteredKeyword !== "" ||
    filteredYear ||
    filteredMonth ||
    filteredAuthor ||
    filteredCategory ||
    filteredSerie;

  const [visibility, setVisibility] = useState(true);

  const ToPreview = () => {
    return (
      <Link
        to="vorschau"
        state={{
          link: true,
          author: filteredAuthor,
          category: filteredCategory,
          month: filteredMonth,
          year: filteredYear,
          keyword: filteredKeyword,
          serie: filteredSerie,
        }}
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mt-3 flex items-center justify-between w-full">
          <div>Zur Vorschau</div>
          <FaChevronRight className="mr-1" />
        </div>
      </Link>
    );
  };

  const SidebarTitle = () => {
    return (
      <>
        <button
          className="flex text-xl items-center flex-1"
          onClick={() => setVisibility(!visibility)}
        >
          {visibility ? (
            <FaChevronDown size={14} className="mr-1 md:hidden" />
          ) : (
            <FaChevronUp size={14} className="mr-1 md:hidden" />
          )}
          <div className="hidden lg:contents">Alle Beiträge</div>
          <div className="lg:hidden">Filter</div>
        </button>
      </>
    );
  };

  const ResetButton = () => {
    return (
      activeFilter && (
        <button onClick={handleReset}>
          <p className="text-red-500 dark:text-red-400 uppercase text-xs font-semibold">
            Reset
          </p>
        </button>
      )
    );
  };

  return (
    <div className="md:w-1/4 md:pl-2.25 order-2 pb-5 md:pb-0 dark:text-white">
      <div className="bg-white rounded-lg shadow-sm p-3 dark:bg-gray-800">
        <div className="flex items-center w-full justify-between">
          <SidebarTitle />
          <ResetButton />
        </div>
        {visibility && (
          <div className="mt-5">
            <FilterOptions
              filteredKeyword={filteredKeyword}
              filteredSerie={filteredSerie}
              filteredCategory={filteredCategory}
              filteredAuthor={filteredAuthor}
              filteredYear={filteredYear}
              setFilteredKeyword={setFilteredKeyword}
              setFilteredAuthor={setFilteredAuthor}
              setFilteredCategory={setFilteredCategory}
              setFilteredYear={setFilteredYear}
              setFilteredMonth={setFilteredMonth}
              setFilteredSerie={setFilteredSerie}
              existingYears={existingYears}
              existingMonths={existingMonths}
              existingAuthors={existingAuthors}
              existingCategories={existingCategories}
              latestSerie={latestSerie}
              handleChangeYear={handleChangeYear}
              handleChangeMonth={handleChangeMonth}
              handleChangeCategory={handleChangeCategory}
              handleChangeAuthor={handleChangeAuthor}
            />
          </div>
        )}
      </div>
      <ToPreview />
    </div>
  );
};

export default BeitraegeSidebar;
